<template>
	<div class="page flex-col">
		<div class="box_1 align-center flex-col" :class="startX<=600?'wid600':''">
			<div class="" v-if="startX<=600">
				<head-top :type="7" :show="1" :startX="startX"></head-top>
				<!-- <img class="image1"
					src="./assets/img/psajvsqlvd6msxh4crxa8fycuzxt4ktpn6b101241c-8223-4d51-9ae3-f595c7e6fa81.png"
					alt=""> -->
				<div class="image1" @click.stop="toIndex(dataMain.banner.picture[0])">
					<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
						style="width: 100%;height:100%">
					<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
				</div>
				<!-- <el-carousel height="100%" class="image1">
					<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id"
						@click.stop="toIndex(item)">
						<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel> -->
			</div>
			<div class="block_1 flex-col" v-else>
				<head-top :type="7" :show="1" :startX="startX"></head-top>
				<div class="section1" @click.stop="toIndex(dataMain.banner.picture[0])">
					<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
						style="width: 100%;height:100%">
					<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
				</div>
				<!-- <el-carousel height="100%" class="section1">
					<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id"
						@click.stop="toIndex(item)">
						<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel> -->
			</div>
			<div class="block_2 mainApp16 flex-col" style="width: 100vw;">
				<div class="box_2  flex-row">
					<div class="section_1 mainApp16 flex-col" id="profile"
						:style="isShow?'width: 100vw;max-width: 1600px;opacity: 1;':startX>600?'width: 5000px;opacity: 0;':''"
						style="">
						<!-- transition: all 3s; width: 5000px;min-width: 300px;max-width: 5000px; -->
						<div class="text-wrapper_1">
							<span class="text_10">A</span>
							<span class="text_11">bout</span>
							<span class="text_12">U</span>
							<span class="text_13">s</span>
						</div>
						<span class="text_14">公司简介</span>
						<div class="paragraph_1 ">
							<img class="image_3" referrerpolicy="no-referrer" :src="dataMain.about.cover_back" />
							<img class="image3" referrerpolicy="no-referrer" :src="dataMain.about.cover_front" />
							<span
								:style="isShow?'width: 100%':startX>600?'width: 50%':''">{{dataMain.about.content}}</span>
						</div>
					</div>
				</div>
				<div class="box_3 flex-col" id="infor">
					<div class="text-wrapper_2 flex-row">
						<span class="text_15"
							:style="isShow1?'position: relative;top:0;opacity: 1':''" v-if="lang=='zh_ch'">来自全球客户的信任与选择</span>
						<span class="text_15"
							:style="isShow1?'position: relative;top:0;opacity: 1':''" v-else>Trust and Choice from Global Customers</span>
					</div>
					<div class="section_2 flex-row" :style="isShow1?'width: 984px;opacity: 1':''">
						<div class=" flex-row justify-center align-center"
							style="width: 25%;border-right: 1px solid #fff;"
							:style="(index+1)==dataMain.about02.length?'border-right:0':''"
							v-for="(item,index) in dataMain.about02" :key="index">
							<div class="section_3 flex-align-justify flex-col">
								<div class="text-wrapper_3">
									<span class="text_16">{{item.word1}}</span>
									<span class="text_17">{{item.word2}}</span>
								</div>
								<span class="text_18">{{item.word3}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="text-wrapper_7" id="gangWeiT">
					<span class="text_28">R</span>
					<span class="text_29">ecruitment</span>
					<span class="text_30">P</span>
					<span class="text_31">osition</span>
				</div>
				<span class="text_32" id="gangWeiN">招聘岗位</span>
				<div class="mainApp16" style="transition: all 0.4s;" :id="'gangWei'+index"
					:style="startX<600?'width: 100vw;min-width:300px;':''" v-for="(item,index) in dataMain.recruitment"
					:key="index">
					<div class="box_4 flex-row justify-between" v-if="(index+1)%2==0">
						<div class=" flex-row">
							<div class="box_5 flex-col justify-between">
								<div class="text-wrapper_8 flex-row justify-between">
									<span class="text_33">{{item.position}}</span>
									<span class="text_34">{{item.salary}}</span>
								</div>
								<span class="text_35">{{item.company}}</span>
							</div>
							<span class="text_36">{{item.address}}</span>
						</div>

						<div class="text-wrapper_9 flex-col align-center justify-center"><span
								class="text_37">投递简历</span>
						</div>
					</div>
					<div class="box_7 flex-row justify-between" v-else>
						<div class=" flex-row">
							<div class="box_8 flex-col justify-between">
								<div class="text-wrapper_10 flex-row justify-between">
									<span class="text_38">{{item.position}}</span>
									<span class="text_39">{{item.salary}}</span>
								</div>
								<span class="text_40">{{item.company}}</span>
							</div>
							<span class="text_41">{{item.address}}</span>
						</div>

						<div class="text-wrapper_11 flex-col align-center justify-center"><span
								class="text_37">投递简历</span>
						</div>
					</div>
				</div>


				<div class="box_21 mainApp16 flex-col">
					<div class="text-wrapper_26" id="fuliT">
						<span class="text_78">C</span>
						<span class="text_79">orporate</span>
						<span class="text_80">B</span>
						<span class="text_81">enefits</span>
					</div>
					<span class="text_82" id="fuliN">企业福利</span>
					<div class="list_1 flex-row" id="fuliL">
						<div class="list-items_1 flex-col justify-between" :id="'fuli'+index"
							v-for="(item, index) in dataMain.about03" :key="index">
							<img class="image_4" referrerpolicy="no-referrer" :src="item.icon" />
							<span class="text_83" v-html="item.title"></span>
							<span class="paragraph_4">{{item.prop}} </span>
						</div>
					</div>
				</div>
				<div class="text-wrapper_28" id="liuChengT">
					<span class="text_84">R</span>
					<span class="text_85">ecruitment</span>
					<span class="text_86">P</span>
					<span class="text_87">rocess</span>
				</div>
				<span class="text_88" id="liuChengN">招聘流程</span>
				<div class="box_22 mainApp16 flex-row justify-between" id="liuChengL">
					<div class="section_14 flex-col" v-for="(item,index) in dataMain.about04" :id="'liuCheng'+index"
						:key="index">
						<span class="text_89">{{item.step}}</span>
						<img class="image_5" referrerpolicy="no-referrer" :src="item.cover" />
						<span class="text_93">{{item.title}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
	</div>
</template>
<script>
	import headTop from "../../components/headTop.vue"
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		recruitment
	} from "../../util/api";
	export default {
		data() {
			return {
				loopData0: [{
						lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/pswo3aimjhrlde9wtfpoqok5m2bupu3wzmd778d4503-3564-4d23-82c7-5e6dedf2859b',
						lanhutext0: '丰厚的薪资待遇'
					},
					{
						lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/psjh1v1ybquxahdok9sisrbl6ce1zru3eoqcfb5fb68-db09-4af7-9b21-83293d97a9ca',
						lanhutext0: '具有竞争力的福利待遇'
					},
					{
						lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/pszc9q88p99qdtuhs6l4udjvb1zxd58zeba3568ba-85de-4bc7-8fd0-07277677fc93',
						lanhutext0: '完善的培养体系'
					},
					{
						lanhuimage0: 'https://lanhu.oss-cn-beijing.aliyuncs.com/ps5y43skl9o6is3vuyypgjc7unuh1vmsid5ef8b71e-76e1-41d4-82de-fe4ad0e5ff39',
						lanhutext0: '欢乐的办公环境'
					}
				],
				constants: {},
				startX: 1200,
				dataMain: '',
				isShow: false,
				isShow1: false
			};
		},
		components: {
			headTop,
			bodyFooter,
			bodyFooter1
		},
		mounted() {
			this.getDate()
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
			window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
			window.addEventListener("scroll", this.scrollHandle1, true);
			window.addEventListener("scroll", this.scrollHand, true);
		},
		deforeDestroy() {
			window.removeEventListener('scroll', this.scrollHandle, true);
			window.removeEventListener('scroll', this.scrollHandle1, true);
			window.removeEventListener('scroll', this.scrollHand, true);
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					// console.log(this.startX, "屏幕宽度");
				}
				if (val < 600) {
					window.removeEventListener('scroll', this.scrollHandle, true);
					window.removeEventListener('scroll', this.scrollHandle1, true);
					window.removeEventListener('scroll', this.scrollHand, true);
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		methods: {
			getDate() {
				recruitment({
					lang: this.lang
				}).then((res) => {
					console.log(res);
					this.dataMain = res.data
					// this.title = res.data.header_nav;
				});
			},
			toIndex(v) {
				if (v.target_rule == 1) {
					this.$router.push("/");
				} else if (v.target_rule == 2) {
					this.$router.push("/aboutUs");
				} else if (v.target_rule == 3) {
					let id = v.param
					this.$router.push("/news/" + id);
				} else if (v.target_rule == 4) {
					let news_id = v.param
					this.$router.push("/newDetail/" + news_id);
				} else if (v.target_rule == 5) {
					let id = v.param
					console.log(id);
					this.$router.push("/product/" + id);
				} else if (v.target_rule == 6) {
					this.$router.push("/companies");
				} else if (v.target_rule == 7) {
					this.$router.push("/Investor");
				} else if (v.target_rule == 8) {
					this.$router.push("/invite");
				} else if (v.target_rule == 9) {
					this.$router.push("/contact");
				}
			},
			scrollHandle() {
				const offset = document.getElementById('profile').getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				// const offsetHeight = offset.height;
				// 进入可视区域
				// console.log(offsetTop,offsetBottom)
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					this.isShow = true
				} else {
					this.isShow = false
					// console.log('移出可视区域');

				}
			},
			scrollHandle1() {
				const offset = document.getElementById('infor').getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				// const offsetHeight = offset.height;
				// 进入可视区域
				// console.log(offsetTop,offsetBottom)
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					this.isShow1 = true
				} else {
					this.isShow1 = false
					// console.log('移出可视区域');

				}
			},
			scrollHand() {
				if (this.startX > 600) {
					let gangWeiT = this.isInViewPortOfOne(document.getElementById('gangWeiT'))
					if (gangWeiT) {
						document.getElementById('gangWeiT').style.fontSize = '72px'
						document.getElementById('gangWeiT').style.opacity = '1'
						document.getElementById('gangWeiN').style.fontSize = '40px'
						document.getElementById('gangWeiN').style.opacity = '1'
					} else {
						document.getElementById('gangWeiT').style.fontSize = '2px'
						document.getElementById('gangWeiT').style.opacity = '0'
						document.getElementById('gangWeiN').style.fontSize = '2px'
						document.getElementById('gangWeiN').style.opacity = '0'
					}
					for (let i = 0; i < this.dataMain.recruitment.length; i++) {
						let id = 'gangWei' + i
						let gangWei = this.isInViewPortOfOne(document.getElementById(id))
						if (gangWei) {
							setTimeout(() => {
								document.getElementById(id).style.opacity = '1'
							}, i * 300 + 300)
						} else {
							document.getElementById(id).style.opacity = '0'
						}
					}
					let fuliT = this.isInViewPortOfOne(document.getElementById('fuliT'))
					if (fuliT) {
						document.getElementById('fuliT').style.fontSize = '72px'
						document.getElementById('fuliT').style.opacity = '1'
						document.getElementById('fuliN').style.fontSize = '40px'
						document.getElementById('fuliN').style.opacity = '1'
					} else {
						document.getElementById('fuliT').style.fontSize = '2px'
						document.getElementById('fuliT').style.opacity = '0'
						document.getElementById('fuliN').style.fontSize = '2px'
						document.getElementById('fuliN').style.opacity = '0'
					}
					let fuliL = this.isInViewPortOfOne(document.getElementById('fuliL'))
					for (let i = 0; i < this.dataMain.about03.length; i++) {
						let id = 'fuli' + i
						if (fuliL) {
							setTimeout(() => {
								document.getElementById(id).style.opacity = '1'
							}, i * 300 + 300)
						} else {
							document.getElementById(id).style.opacity = '0'
						}
					}
					let liuChengT = this.isInViewPortOfOne(document.getElementById('liuChengT'))
					if (liuChengT) {
						document.getElementById('liuChengT').style.fontSize = '72px'
						document.getElementById('liuChengT').style.opacity = '1'
						document.getElementById('liuChengN').style.fontSize = '40px'
						document.getElementById('liuChengN').style.opacity = '1'
					} else {
						document.getElementById('liuChengT').style.fontSize = '2px'
						document.getElementById('liuChengT').style.opacity = '0'
						document.getElementById('liuChengN').style.fontSize = '2px'
						document.getElementById('liuChengN').style.opacity = '0'
					}
					let liuChengL = this.isInViewPortOfOne(document.getElementById('liuChengL'))
					for (let i = 0; i < this.dataMain.about04.length; i++) {
						let id = 'liuCheng' + i
						if (liuChengL) {
							setTimeout(() => {
								document.getElementById(id).style.opacity = '1'
							}, i * 300 + 300)
						} else {
							document.getElementById(id).style.opacity = '0'
						}
					}
				}
			},
			isInViewPortOfOne(el) {
				// viewPortHeight 兼容所有浏览器写法
				const offset = el.getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					return true
				} else {
					return false
					// console.log('移出可视区域');
				}
			},
			// toIndex() {
			// 	this.$router.push('/')
			// },
			// toUs() {
			// 	this.$router.push('/aboutUs')
			// },
			// toNews() {
			// 	this.$router.push('/news')
			// },
			// toCenter() {
			// 	this.$router.push('/product')
			// },
			// toSub() {
			// 	this.$router.push('/companies')
			// },
			// toRelat() {
			// 	this.$router.push('/Investor')
			// },
			// toRecruit() {
			// 	this.$router.push('/invite')
			// },
			// toContact() {
			// 	this.$router.push('/contact')
			// },
		}
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss" scoped>
	.wid600 {
		width: 100%;

		.block_2 {
			width: 100%;
			min-width: 300px;

			.box_2 {
				width: 100%;
				height: auto;
				margin: 16.667rem 0 0 7.667rem;
				position: relative;

				.section_1 {
					width: 94%;
					height: auto;
					margin: 0;
					padding-left: 0px;

					.text-wrapper_1 {
						width: 100%;
						height: auto;
						font-size: 8.667rem;
						line-height: 5.333rem;
						text-transform: uppercase;
						margin: 0;
						// margin-top: 13.333rem;
					}

					.text_14 {
						width: 100%;
						height: auto;
						font-size: 5rem;
						line-height: 5rem;
						text-transform: uppercase;
						margin: 0 auto;
						margin-top: 4rem;
					}

					.paragraph_1 {
						width: 100%;
						height: auto;
						margin-top: 7rem;
						font-size: 3.667rem;
						line-height: 7.333rem;

						>span {
							display: block;

							// &:nth-child(1) {
							// 	width: 50%;
							// }

							// &:nth-child(2) {
							// 	width: 65%;
							// }
							&:nth-child(3) {
								width: 91vw;
							}
						}
					}

					.paragraph_2 {
						width: 100%;
						height: auto;
						margin-top: 6rem;
						font-size: 3.667rem;
						line-height: 7.333rem;
					}

					.paragraph_3 {
						width: 100%;
						height: auto;
						margin-top: 6rem;
						font-size: 3.667rem;
						line-height: 7.333rem;
					}
				}

				.image_3 {
					width: 56rem;
					height: 30rem;
					margin-left: 18px;
					margin-top: -90px;
					margin-right: 50px;
				}

				.image3 {
					width: 44rem;
					height: 27rem;
					margin-left: 18px;
					margin-top: -50px;
					margin-right: 17px;
				}
			}

			.box_3 {
				width: 100%;
				height: auto;
				margin: 0;
				margin-top: 13.333rem;
				position: relative;

				.text-wrapper_2 {
					width: 100%;
					height: auto;
					margin-top: 13.333rem;

					.text_15 {
						font-size: 5rem;
						line-height: 5rem;
						width: 100%;
						position: relative;
						top: 0px;
						opacity: 1;
					}
				}

				.section_2 {
					width: 100% !important;
					height: auto;
					margin-top: 9.667rem;
					margin-bottom: 10rem;
					align-items: center;
					justify-content: space-around;
					opacity: 1;

					.section_3 {
						width: auto;
						height: auto;
						margin-top: 0;

						.text-wrapper_3 {
							width: auto;
							height: auto;
							line-height: 4.333rem;

							.text_16 {
								font-size: 4.333rem;
								line-height: 4.333rem;
							}

							.text_17 {
								font-size: 2.2rem;
								line-height: 2.2rem;
							}
						}

						.text_18 {
							width: auto;
							height: auto;
							font-size: 2rem;
							line-height: 2rem;
							margin-top: 2.667rem;
						}
					}

					.section_4 {
						width: 0.2rem;
						height: 12rem;
						margin-left: 0;
					}

					.section_5 {
						width: auto;
						height: auto;
						margin-top: 0;
						margin-left: 0;

						.text-wrapper_3 {
							width: auto;
							height: auto;
							line-height: 4.333rem;

							.text_16 {
								font-size: 4.333rem;
								line-height: 4.333rem;
							}

							.text_17 {
								font-size: 2.2rem;
								line-height: 2.2rem;
							}
						}

						.text_18 {
							width: auto;
							height: auto;
							font-size: 2rem;
							line-height: 2rem;
							margin-top: 2.667rem;
						}
					}
				}
			}

			.text-wrapper_7 {
				width: 100%;
				height: auto;
				font-size: 8.667rem;
				line-height: 5.333rem;
				text-transform: uppercase;
				margin: 0;
				margin-top: 13.333rem;
			}

			.text_32 {
				width: 100%;
				height: auto;
				font-size: 5rem;
				line-height: 5rem;
				text-transform: uppercase;
				margin: 0 auto;
				margin-top: 4rem;
				margin-bottom: 8rem;
			}

			.box_4 {
				width: 100%;
				// height: 17.333rem;
				align-items: center;
				padding-bottom: 0;
				margin: 0 auto;
				// margin-top: 10rem;

				.box_5 {
					width: auto;
					height: auto;
					margin: 4rem 0 4rem 5.5rem;

					.text-wrapper_8 {
						width: 39.667rem;
						height: auto;

						.text_33 {
							width: auto;
							height: auto;
							font-size: 3.667rem;
							line-height: 3.667rem;

						}

						.text_34 {
							width: auto;
							height: auto;
							font-size: 3.667rem;
							line-height: 3.667rem;
						}
					}

					.text_35 {
						width: auto;
						height: auto;
						font-size: 2.667rem;
						line-height: 2.667rem;
						margin-top: 3.333rem;
					}
				}

				.text_36 {
					width: auto;
					height: auto;
					font-size: 2.667rem;
					line-height: 2.667rem;
					margin: 0;
					margin-left: .5rem;
					margin-top: 11.4rem;
				}

				.box_6 {
					height: 9.667rem;
					margin: 0 auto;
					margin-right: 4rem;
				}

				.text-wrapper_9 {
					margin: 0;
					width: 16.333rem;
					height: 7rem;
					margin-right: 3rem;

					.text_37 {
						font-size: 3rem;

					}
				}
			}

			.box_7 {
				width: 100%;
				// height: 17.333rem;
				align-items: center;
				margin: 0 auto;
				padding-bottom: 0;

				.box_8 {
					width: auto;
					height: auto;
					margin: 4rem 0 4rem 5.5rem;

					.text-wrapper_10 {
						width: 39.667rem;
						height: auto;

						.text_38 {
							width: auto;
							height: auto;
							font-size: 3.667rem;
							line-height: 3.667rem;

						}

						.text_39 {
							width: auto;
							height: auto;
							font-size: 3.667rem;
							line-height: 3.667rem;
						}
					}

					.text_40 {
						width: auto;
						height: auto;
						font-size: 2.667rem;
						line-height: 2.667rem;
						margin-top: 3.333rem;
					}
				}

				.text_41 {
					width: auto;
					height: auto;
					font-size: 2.667rem;
					line-height: 2.667rem;
					margin: 0;
					margin-left: .5rem;
					margin-top: 11.4rem;
				}

				.box_9 {
					height: 9.667rem;
					margin: 0 auto;
					margin-right: 4rem;
				}

				.text-wrapper_11 {
					margin: 0;
					width: 16.333rem;
					height: 7rem;
					margin-right: 3rem;

					.text_37 {
						font-size: 3rem;
					}
				}
			}

			.box_10 {
				width: 94%;
				height: 17.333rem;
				align-items: center;
				margin: 0 auto;

				.section_10 {
					width: auto;
					height: auto;
					margin: 4rem 0 4rem 5.5rem;

					.text-wrapper_12 {
						width: 39.667rem;
						height: auto;

						.text_43 {
							width: auto;
							height: auto;
							font-size: 3.667rem;
							line-height: 3.667rem;

						}

						.text_44 {
							width: auto;
							height: auto;
							font-size: 3.667rem;
							line-height: 3.667rem;
						}
					}

					.text_45 {
						width: auto;
						height: auto;
						font-size: 2.667rem;
						line-height: 2.667rem;
						margin-top: 3.333rem;
					}
				}

				.text_46 {
					width: auto;
					height: auto;
					font-size: 2.667rem;
					line-height: 2.667rem;
					margin: 0;
					margin-left: .5rem;
					margin-top: 7rem;
				}

				.section_11 {
					height: 9.667rem;
					margin: 0 auto;
					margin-right: 4rem;
				}

				.text-wrapper_13 {
					margin: 0;
					width: 16.333rem;
					height: 7rem;
					margin-right: 3rem;

					.text_37 {
						font-size: 3rem;
					}
				}
			}

			.box_21 {
				width: 100%;
				height: auto;
				margin: 0;
				margin-top: 11rem;
				min-width: 300px;

				.text-wrapper_26 {
					width: 100%;
					height: auto;
					font-size: 8.667rem;
					line-height: 5.333rem;
					text-transform: uppercase;
					margin: 0;
					margin-top: 15rem;
				}

				.text_82 {
					width: 100%;
					height: auto;
					font-size: 5rem;
					line-height: 5rem;
					text-transform: uppercase;
					margin: 0 auto;
					margin-top: 4rem;
				}

				.list_1 {
					width: 86%;
					margin-top: 10rem;
					flex-wrap: wrap;

					.list-items_1 {
						margin-bottom: 10rem;

						.image_4 {
							width: 13rem;
							height: 13rem;
						}

						.text_83 {
							width: 100%;
							height: auto;
							font-size: 4rem;
							line-height: 4rem;
							margin-top: 4rem;
						}

						.paragraph_4 {
							width: 45.333rem;
							height: auto;
							font-size: 2.667rem;
							line-height: 4.5rem;
							margin-top: 3.667rem;
						}
					}
				}
			}

			.text-wrapper_28 {
				width: 100%;
				height: auto;
				font-size: 8.667rem;
				line-height: 5.333rem;
				text-transform: uppercase;
				margin: 0;
				margin-top: 15rem;
			}

			.text_88 {
				width: 100%;
				height: auto;
				font-size: 5rem;
				line-height: 5rem;
				text-transform: uppercase;
				margin: 0 auto;
				margin-top: 4rem;
			}

			.box_22 {
				width: 93%;
				flex-wrap: wrap;
				margin-top: 0rem;
				margin-bottom: 13rem;
				min-width: 300px;

				.section_14 {
					width: 53.333rem;
					height: auto;
					margin-top: 10rem;

					.text_89 {
						font-size: 8rem;
						line-height: 9rem;
						margin-bottom: 2rem;
					}

					.image_5 {
						width: 100%;
						height: auto;

					}

					.text_93 {
						font-size: 4rem;
						line-height: 4rem;
						padding: 4.5rem 4rem 4.3rem 4rem;
					}
				}
			}
		}
	}
</style>